import { Ionicons } from '@expo/vector-icons';
import { LinearGradient } from 'expo-linear-gradient';
import { getAuth } from 'firebase/auth';
import { collection, doc, getDocs, getFirestore, query, serverTimestamp, setDoc, updateDoc, where } from 'firebase/firestore';
import { getDownloadURL, getStorage, ref } from 'firebase/storage';
import { useEffect, useRef, useState } from 'react';
import { ActivityIndicator, StyleSheet, TouchableOpacity, View } from 'react-native';
import { useDimensions } from 'react-native-web-hooks';
import ReactPlayer from 'react-player';
import { useQuery } from 'react-query';
import Button from '../common/Button';
import Modal from '../common/Modal';
import StyledText from '../common/StyledText';

function secondsToHHMMSS(seconds) {
	const hours = Math.floor(seconds / 3600);
	const remainingSeconds = seconds % 3600;
	const minutes = Math.floor(remainingSeconds / 60);
	const remainingSecondsFinal = remainingSeconds % 60;
	return `${hours.toString().padStart(2, "0")}:${minutes.toString().padStart(2, "0")}:${remainingSecondsFinal.toString().padStart(2, "0")}`;
}
function secondsToMMSS(seconds) {
	const remainingSeconds = seconds % 3600;
	const minutes = Math.floor(remainingSeconds / 60);
	const remainingSecondsFinal = remainingSeconds % 60;
	return `${minutes.toString().padStart(2, "0")}:${remainingSecondsFinal.toString().padStart(2, "0")}`;
}


// PONER UN MENSAJE AL COMENZAR EL VIDEO DICIENDO QUE VA A TENER 3 PRUEBAS

// ANTES DEL PLAZO NO SE PUEDE VER
// DESPUES DEL PLAZO SI SE PUEDE VER HAYAS APROBADO O SUSPENDIDO
// DURANTE EL PLAZO HAS APROBADO PUEDES VERLO CUANTAS VECES QUIERAS PERO NO REGISTRA

// SI SE HA SUSPENDIDO EN EL MOMENTO DEL SUSPENSO, SE PARA EL VIDEO Y APARECE UN MENSAJE PARA REINICIAR EL INTENTO

// PONER BOTON DE REINICIAR QUE LANZA UN MENSAJE

// PONER AL FINAL UN BOTON DE COMPLETAR LA ACTIVIDAD


const useFile = (uri: string, currentTime: number, lastWatched: number, completed: boolean) => {
	const [file, setFile] = useState(null)
	const storage = getStorage()

	useEffect(() => {

		if (uri && currentTime <= lastWatched) {
			if (uri.includes("http")) {
				setFile(uri + (completed ? "" : ("#t=" + lastWatched)))
			} else if (uri.includes("base64")) {
				setFile(uri + (completed ? "" : ("#t=" + lastWatched)))
			} else {
				const fileRef = ref(storage, uri)
				getDownloadURL(fileRef).then(res => {
					setFile(res + (completed ? "" : ("#t=" + lastWatched)))
				}).catch(err => {
					console.log(err)
				})
			}
		}
	}, [uri, lastWatched, currentTime])

	return file

}


const useTimesToCheck = (duration, deadline) => {

	const numberOfChecks = 3
	const [timesToCheck, setTimesToCheck] = useState([])

	useEffect(() => {

		if (duration > 0) {
			if (deadline) {
				setTimesToCheck(deadline.timesToCheck || [])
			} else {
				const firstThird = Math.round(duration / numberOfChecks)
				const secondThird = Math.round(duration / numberOfChecks * 2)
				const firstThirdRandom = Math.round(Math.random() * firstThird)
				const secondThirdRandom = Math.round(Math.random() * firstThird) + firstThird
				const thirdThirdRandom = Math.round(Math.random() * firstThird) + secondThird
				const times = [Math.round(firstThirdRandom), Math.round(secondThirdRandom), Math.round(thirdThirdRandom)]
				setTimesToCheck(times)
			}
		}
	}, [duration, deadline])

	return timesToCheck
}

const TrackedVideo = ({ uri, content, advanceRef, contentId }) => {


	const dimensions = useDimensions()

	const isPhone = dimensions.window.width < 1000


	const [playing, setPlaying] = useState(false)
	// const [duration, setDuration] = useState(0)
	const [currentTime, setCurrentTime] = useState(0)
	const [areYouThere, setAreYouThere] = useState(false)
	const [restartModal, setRestartModal] = useState(false)
	const [firstMessage, setFirstMessage] = useState(false)
	const [completeModal, setCompleteModal] = useState(false)

	const [checking, setChecking] = useState(false)


	const videoRef = useRef<ReactPlayer>(null)
	const auth = getAuth()
	const db = getFirestore()


	const currentDeadline = !content?.deadlines ? null : content?.deadlines?.length === 0 ? null : content?.deadlines?.find((el) => el?.start?.toDate() < Date.now() && el?.end?.toDate() > Date.now())
	const allDeadlinesHavePassed = !content?.deadlines ? false : content?.deadlines?.length === 0 ? false : content?.deadlines?.every((el) => el?.end?.toDate() < Date.now())
	const noDeadlineHasStarted = !content?.deadlines ? true : content?.deadlines?.length === 0 ? true : content?.deadlines?.every((el) => el?.start?.toDate() > Date.now())


	const contentAdvance = {
		grade: null,
		comments: null,
		newUpdate: false,
		lastCorrection: null,
		correctionBy: null,

		name: content.title,
		contentId: content.id,
		contentType: "TASK",

		tenantId: advanceRef.path.split("/")[1],

		courseName: "",
		courseTag: "",
		courseId: "",

		// User that submitted the deadline advance
		userId: auth.currentUser.uid,
		userImage: "",
		userName: "",
		userSurname: "",

		teachers: [],
		status: null,
	}

	const advancesRef = query(collection(db, `${advanceRef?.path}/videoAttendance`), where("contentId", "==", contentId))

	const { data, error, isLoading, refetch } = useQuery({
		queryKey: ['advanceContent', contentId],
		refetchOnWindowFocus: false,
		queryFn: () => getDocs(advancesRef).then(res => {
			const data = res.docs?.map((el) => ({ ...el?.data(), deadlineId: el?.data()?.deadlineId ? el?.data()?.deadlineId : el?.id }))
			if (data) {
				setupData(data)
			}
			return data
		})
	})


	const completed = data?.some((el) => el?.status === "APPROVED")
	const approved = data?.some((el) => el?.status === "APPROVED")
	const deadline = approved ? data?.find((el) => el?.status === "APPROVED")?.deadlineId : currentDeadline ? currentDeadline?.id : null
	const deadlineAdvanceData = data?.find((el) => el?.deadlineId === deadline)
	const deadlineAdvance = doc(db, `${advanceRef.path}/videoAttendance/${deadline}`)
	const duration = Math.round(content?.contentLength || 1800)
	const checks = deadlineAdvanceData?.checks || []


	const timesToCheck = useTimesToCheck(duration, deadlineAdvanceData)
	const file = useFile(uri, currentTime, deadlineAdvanceData?.lastWatched || 0, completed)



	const setupData = (data) => {

		if (playing) {
			return
		}

		const hasDeadlines = !(!content?.deadlines || content?.deadlines?.length === 0)
		const lastDeadlineApproved = data?.find((el) => el?.status === "APPROVED")

		if (hasDeadlines && lastDeadlineApproved) {
		} else {
			if (!currentDeadline) {
				return
			}
			const allDeadlinesHavePassed = content?.deadlines?.every((el) => el?.end?.toDate() < Date.now())

			if (!currentDeadline || allDeadlinesHavePassed) {

			} else {
				const deadlineAdvance = data?.find((el) => el?.deadlineId === currentDeadline?.id)
				if (deadlineAdvance) {
					if (deadlineAdvance?.status) {
						return
					}
					setCurrentTime(deadlineAdvance.lastWatched)
				}
			}
		}
	}

	const showOverlay = () => {
		if (videoRef.current && !completed && playing) {
			setAreYouThere(true)
			setPlaying(false)
		}
	}

	const updatedLastWatched = (t) => {
		//updated the last watched time on the currentAdvance deadline
		updateDoc(deadlineAdvance, {
			lastWatched: t,
			updatedAt: serverTimestamp()
		})
	}

	const startVideo = async () => {

		setFirstMessage(false)
		setPlaying(true)

		await setDoc(deadlineAdvance, {
			...contentAdvance,
			lastWatched: 0,
			createdAt: serverTimestamp(),
			updatedAt: serverTimestamp(),
			timesToCheck: timesToCheck,
			checks: [],
		}, { merge: true })

		refetch()
	}

	const updateCheckedTimes = async (c) => {
		setChecking(true)
		try {
			await updateDoc(deadlineAdvance, {
				lastWatched: Math.round(videoRef?.current?.getCurrentTime() || 0),
				updatedAt: serverTimestamp(),
				checks: c
			})

			setAreYouThere(false)
			setPlaying(true)

			refetch()

		} catch (err) {
			console.log(err)
			alert("Ha ocurrido un error al actualizar los datos")
		} finally {
			setChecking(false)
		}
	}

	const restartAdvance = async () => {
		setChecking(true)
		try {
			setRestartModal(false)

			const firstThird = Math.round(duration / 3)
			const secondThird = Math.round(duration / 3 * 2)
			const firstThirdRandom = Math.round(Math.random() * firstThird)
			const secondThirdRandom = Math.round(Math.random() * firstThird) + firstThird
			const thirdThirdRandom = Math.round(Math.random() * firstThird) + secondThird
			const times = [Math.round(firstThirdRandom), Math.round(secondThirdRandom), Math.round(thirdThirdRandom)]

			await setDoc(deadlineAdvance, {
				...contentAdvance,
				status: null,
				lastWatched: 0,
				updatedAt: serverTimestamp(),
				timesToCheck: times,
				checks: [],
			}, { merge: true })

			refetch()

		} catch (err) {
			console.log(err)
			alert("Ha ocurrido un error al actualizar los datos")
		} finally {
			setChecking(false)
		}
	}

	const completeContent = async () => {
		setChecking(true)
		try {
			const pass = checks.reduce((acc, el) => {
				if (el?.status === true) {
					return acc + 1
				} else {
					return acc
				}
			}, 0) >= 2

			await updateDoc(deadlineAdvance, {
				status: !pass ? "FAILED" : "APPROVED",
				updatedAt: serverTimestamp(),
			})

			setPlaying(false)
			videoRef.current.seekTo(0, "seconds")
			refetch()
			setCompleteModal(false)

		} catch (err) {
			alert("Ha ocurrido un error al actualizar los datos")
		} finally {
			setChecking(false)
		}

	}


	if (error) {
		return (
			<View style={{ flex: 1, minHeight: 400, alignItems: "center", justifyContent: "center" }}>
				<StyledText style={{ fontSize: 34, fontFamily: "Title", textAlign: "center" }}>Ocurrió un error cargando el contenido</StyledText>
			</View>
		)
	}

	if (isLoading) {
		return (
			<View style={{ flex: 1, minHeight: 400, alignItems: "center", justifyContent: "center" }}>
				<ActivityIndicator />
			</View>
		)
	}
	return (
		<>
			<View style={{ borderRadius: 15, overflow: "hidden" }}>
				{noDeadlineHasStarted || (!currentDeadline && !noDeadlineHasStarted && !approved) ?
					<View style={{ backgroundColor: "rgba(0,0,0,.7)", alignItems: "center", justifyContent: "center", zIndex: 15, position: "absolute", top: 0, left: 0, right: 0, bottom: 0 }}>
						<StyledText style={{ fontSize: 34, color: 'white' }}>
							El plazo para visualizar el video ha finalizado o aún no está disponible
						</StyledText>
					</View>
					: null
				}
				{!completed && !noDeadlineHasStarted && (currentDeadline && !noDeadlineHasStarted) ?
					<>
						<TouchableOpacity
							onPress={() => {
								if (currentTime === 0 && !completed) {
									setFirstMessage(true)
								} else {
									setPlaying(!playing)
								}
							}}
							style={[styles.shadow, { zIndex: 10, position: "absolute", alignItems: "center", justifyContent: "center", bottom: 15, left: 15, width: 60, height: 60, borderRadius: 30, backgroundColor: "white" }]}>
							<Ionicons name={playing ? "pause" : "play"} size={30} />
						</TouchableOpacity>
						<View style={{ zIndex: 10, position: "absolute", right: 15, bottom: 15, flexDirection: "row" }}>
							<StyledText style={{ fontFamily: "TitleWide", fontSize: 18, color: 'white' }}>
								{secondsToHHMMSS(duration)} - {secondsToHHMMSS(currentTime || 0)}
							</StyledText>
						</View>
						<LinearGradient
							colors={['rgba(0,0,0,0)', 'rgba(0,0,0,.7)']}
							style={{ width: "100%", height: 100, position: "absolute", zIndex: 5, bottom: 0, left: 0, right: 0 }} />
					</>
					: null

				}
				{areYouThere ?
					<View style={{ backgroundColor: "rgba(0,0,0,.7)", alignItems: "center", justifyContent: "center", zIndex: 15, position: "absolute", top: 0, left: 0, right: 0, bottom: 0 }}>
						<StyledText style={{ fontSize: isPhone ? 16 : 34, color: 'white', textAlign: "center" }}>
							Pulsa el botón para confirmar la visualización y continuar el video
						</StyledText>
						<CountDown time={90}
							onEnd={() => {
								// setAreYouThere(false)
								// setPlaying(true)
								const c = [
									...checks,
									{
										time: currentTime,
										status: false
									}
								]
								updateCheckedTimes(c)
							}} />
						<TouchableOpacity
							disabled={checking}
							onPress={() => {

								const c = [
									...checks,
									{
										time: currentTime,
										status: true
									}
								]
								updateCheckedTimes(c)
							}} style={{ flexDirection: "row", marginTop: 15, alignItems: "center", justifyContent: "center", borderRadius: 7, paddingHorizontal: 20, paddingVertical: 10, backgroundColor: "white" }}>
							<StyledText style={{ fontFamily: "TitleWide", marginRight: 5 }}>
								Continuar video
							</StyledText>
							<Ionicons name="play" size={24} />
						</TouchableOpacity>
					</View>
					: null
				}

				{completeModal ?
					<View style={{ backgroundColor: "rgba(0,0,0,.7)", alignItems: "center", paddingHorizontal: 15, justifyContent: "center", zIndex: 15, position: "absolute", top: 0, left: 0, right: 0, bottom: 0 }}>
						<StyledText style={{ textAlign: "center", fontSize: isPhone ? 16 : 34, color: 'white' }}>
							Has visualizado todo el contenido, si has marcado los momentos clave, pulsa el botón para completar el contenido, si no puedes reiniciar el intento en la duración de la convocatoria.
						</StyledText>
						<TouchableOpacity onPress={() => {
							completeContent()
						}} style={{ flexDirection: "row", marginTop: 15, alignItems: "center", justifyContent: "center", borderRadius: 7, paddingHorizontal: 20, paddingVertical: 10, backgroundColor: "white" }}>
							<StyledText style={{ fontFamily: "TitleWide", marginRight: 5 }}>
								Completar Contenido
							</StyledText>
							<Ionicons name="checkmark-circle-outline" size={24} />
						</TouchableOpacity>
					</View>
					: null
				}


				{file ?
					<ReactPlayer
						playsinline={true}
						// onDuration={e => {
						// 	if (!completed) {
						// 		setDuration(Math.round(e))
						// 	}
						// }}
						onEnded={() => setCompleteModal(true)}
						onProgress={(ev) => {
							if (!completed) {
								const isTime = timesToCheck.includes(Math.floor(ev.playedSeconds))
								const timeIsAlreadyChecked = checks.find(x => x.time === Math.floor(ev.playedSeconds))

								// update updatedLastWatched function every 30 seconds
								if (Math.floor(ev.playedSeconds) % 30 === 0 && !isTime && Math.floor(ev.playedSeconds) > 0 && playing) {
									updatedLastWatched(Math.floor(ev.playedSeconds))
								}

								//check if the current time is one of the times to check and if its not already being checked on the checks array
								if (isTime && !timeIsAlreadyChecked) {
									showOverlay()
								}

								if (playing) {
									setCurrentTime(Math.floor(ev.playedSeconds))
								}
							}
						}}
						ref={videoRef}
						width={"100%"}
						height={"auto"}
						stopOnUnmount={true}
						url={file}
						config={{
							file: {
								attributes: {
									controlsList: "nofullscreen",
								},
							},
							youtube: {
								playerVars: {
									controls: 0,
									fs: 0,
									playsinline: 1,
								},
							},
						}}
						playing={noDeadlineHasStarted || (!currentDeadline && !noDeadlineHasStarted) && !approved ? false : playing}
						controls={noDeadlineHasStarted || (!currentDeadline && !noDeadlineHasStarted) && !approved ? false : completed ? true : false}
					/>
					: null
				}
			</View>

			{allDeadlinesHavePassed || noDeadlineHasStarted || (!currentDeadline && !noDeadlineHasStarted) ? null : <View style={{ marginTop: 15, alignItems: "cemter", flexDirection: "row", justifyContent: "space-between" }}>
				{timesToCheck?.length > 0 ? <View style={{ gap: 10, flexDirection: "row", alignItems: "center" }}>
					<StyledText style={{ fontFamily: "TitleWide", fontSize: isPhone ? 10 : 12 }}>
						Presencialidad
					</StyledText>
					{timesToCheck?.map((x, i) => {
						return (
							<View key={i} style={{ width: 20, height: 20, borderRadius: 10, backgroundColor: checks[i] ? checks[i]?.status ? "#8bc34a" : "red" : "gainsboro" }} />
						)
					})}

				</View> : <View />}

				{!file || (completed && (!deadlineAdvance || deadlineAdvance?.status === "APPROVED" || approved)) ?
					null :
					<Button
						onPress={() => {
							setPlaying(false)
							setRestartModal(true)
						}}
						label="Reiniciar Intento"
						round={7}
						height={40}
					/>
				}
			</View>}

			{restartModal ?
				<ModalSure closeCallback={() => setRestartModal(false)} pressCallback={restartAdvance} />
				: null
			}
			{firstMessage ?
				<InfoModal
					closeCallback={() => {
						setFirstMessage(false)
						setPlaying(false)
					}}
					playCallback={() => {
						startVideo()
					}}
				/>
				: null
			}
		</>
	);
}

export default TrackedVideo;

const CountDown = ({ time, onEnd }) => {

	const [seconds, setSeconds] = useState(time)

	useEffect(() => {
		if (seconds > 0) {
			setTimeout(() => {
				setSeconds(seconds - 1)
			}, 1000)
		} else {
			onEnd()
		}
	}, [seconds])



	return (
		<StyledText style={{ fontSize: 34, color: 'white' }}>
			{secondsToMMSS(seconds)}
		</StyledText>
	);
}

const ModalSure = ({ closeCallback, pressCallback }) => {
	return (
		<Modal
			onClickOutside={closeCallback}
			modalContainerStyle={{
				maxWidth: 500,
			}}
			padding={15}
		>
			<View style={{ rowGap: 10 }}>
				<View style={{ alignItems: "center" }}>
					<Ionicons name="alert-circle-outline" size={100} color="red" />
				</View>
				<StyledText style={{ fontSize: 18, marginBottom: 15 }}>
					¿Estás seguro de que quieres reiniciar el intento? Se perderán todos los datos y tendrás de comenzar el video de nuevo.
				</StyledText>
				<View style={{ flexDirection: "row", alignItems: "center", justifyContent: "space-between" }}>
					<Button onPress={closeCallback} label="Cancelar" round="7px" height="40px" color="gray" />
					<Button label="Confirmar" round="7px" height="40px" onPress={pressCallback} />
				</View>
			</View>
		</Modal>
	)
}

const InfoModal = ({ closeCallback, playCallback }) => {
	return (
		<Modal
			onClickOutside={closeCallback}
			modalContainerStyle={{
				maxWidth: 500,
			}}
			padding={15}
		>
			<View style={{ rowGap: 10 }}>
				<View style={{ alignItems: "center" }}>
					<Ionicons name="warning-outline" size={100} color="orange" />
				</View>
				<StyledText style={{ fontSize: 18, marginBottom: 15 }}>
					{/* <Ionicons name="caret-forward" style={{ marginRight: 5 }} size={22} color="green" /> */}
					A lo largo del video aparecerán 3 mensajes de confirmación de visualización.
				</StyledText>
				<StyledText style={{ fontSize: 18, marginBottom: 15 }}>
					{/* <Ionicons name="caret-forward" style={{ marginRight: 5 }} size={22} color="green" /> */}
					Para superar este ejercicio deberás confirmar al menos 2 de las 3 veces que aparezca ese mensaje.
				</StyledText>
				<StyledText style={{ fontSize: 18, marginBottom: 15 }}>
					{/* <Ionicons name="caret-forward" style={{ marginRight: 5 }} size={22} color="green" /> */}
					Tendrás 90 segundos para confirmar cada mensaje.
				</StyledText>
				<StyledText style={{ fontSize: 18, marginBottom: 15 }}>
					{/* <Ionicons name="caret-forward" style={{ marginRight: 5 }} size={22} color="green" /> */}
					Puedes repetir tantas veces como quieras el ejercicio durante el tiempo disponible en las convocatorias.
				</StyledText>
				<View style={{ flexDirection: "row", alignItems: "center", justifyContent: "space-between" }}>
					<Button onPress={closeCallback} label="Cancelar" round="7px" height="40px" color="gray" />
					<Button onPress={playCallback} label="Comenzar" round="7px" height="40px" />
				</View>
			</View>
		</Modal>
	)
}

const styles = StyleSheet.create({
	shadow: {
		shadowColor: "rgba(0, 0, 0, 0.3)",
		shadowOffset: { width: 0, height: 5 },
		shadowOpacity: 0.4,
		shadowRadius: 10,
		elevation: 10,
	}
});
